import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import ReactS3 from "react-s3";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";

import "./ItemComposition.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
// const getCatagories = "/routes/dashboard/getCatagories?";
const getItemsToActOn = "/routes/dashboard/getItemsToActOn?";
const saveComposedItem = "/routes/dashboard/saveComposedItem";
const saveCompositionWip = "/routes/inventory/saveCompositionWip";
// const getCommunityStores = "/routes/dashboard/getCommunityStores?";
const getCompositionWIP = "/routes/inventory/getCompositionWIP?";
const updateReceiptDA = "/routes/inventory/updateReceiptDA";

const picTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const pdfTypes = ["pdf"];

const maxPicSize = 1000000; // 1MB
const maxPdfSize = 5000000; // 5MB

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111124000";

class ItemComposition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      targetUnitName: "",
      searchItemName: "",
      selectedItemName: "",
      outputQty: 0,
      compositionCost: 0,

      invClassification: "",
      categorySelected: "",

      fetchedTargetItemFlag: false,
      targetItemList: null,

      selectedTargetItem: null,
      showTargetSubItemFlag: false,
      selectedSubItem: null,

      searchRawItem: "",

      rawItemList: null,
      compRawItemList: [],
      rawItemSelected: null,
      rawItemSelectedFlag: false,
      usedQty: 0,

      errMsg: "",
      errFlag: false,

      iAgree: false,

      confirmToCompose: false,
      okToCompose: false,

      subDirName: "",
      itemComposeFlag: true,
      attachPicFlag: false,
      attachPdfFlag: true,
      picS3FileData: null,
      pdfS3FileData: null,
      fileUploads: [],
      s3bucket: "",

      paymentMedium: "cash",
      expenseNote: "",

      bbItemType: "raw",
      fetchedRawItemFlag: false,
      itemNameType: "item",

      compositionName: "",
      subRawItemFlag: false,
      selectedRawSubItem: null,
      compositionId: 0,
      noteMgmtFlag: false,
      notes: [],
      noteText: "",
      // composition built of
      composedOfItems: [],
      multiStoreFlag: true,
      // stores: [],
      // selectedStore: null,
      storeType: "all",
      showStoreFlag: false,
      showCompWipFlag: false,
      compwip: [],
      dasize: 0,
    };
  }

  componentDidMount = async () => {
    // await this.getCommunityStores();
  };

  // getCommunityStores = async () => {
  //   let param = "communityId=" + this.props.communityId;
  //   let url = baandaServer + getCommunityStores + param;
  //   try {
  //     let strret = await axios.get(url);
  //     if (strret.data.status === "success") {
  //       // If not creator or central org authorized members
  //       let wrkStores = [];
  //       strret.data.Msg.forEach((obj) => {
  //         let insert = false;
  //         if (obj.ownerBaandaId === this.props.auth.user.baandaId)
  //           insert = true;
  //         else {
  //           obj.members.forEach((mem) => {
  //             if (mem.baandaId === this.props.auth.user.baandaId) insert = true;
  //           });
  //         }
  //         if (insert) wrkStores.push(obj);
  //       });
  //       this.setState({
  //         stores: wrkStores,
  //       });
  //     } else {
  //       this.setState({
  //         errMsg: strret.data.Msg,
  //         errFlag: true,
  //       });
  //     }
  //   } catch (err) {
  //     this.setState({
  //       errMsg: err.message,
  //       errFlag: true,
  //     });
  //   }
  // };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  selectThisComposition = async (obj) => {
    let outputQty = 0;
    if (obj.target) outputQty = obj.target.outputQty;

    let confirmToCompose = false;
    if (outputQty > 0) confirmToCompose = true;

    let dasize = 0;
    if (obj.fileUploads.length > 0) {
      dasize += obj.size;
    }

    let picS3FileData = null;
    let pdfS3FileData = null;

    if (obj.fileUploads.length > 0) {
      obj.fileUploads.forEach((elm) => {
        if (elm.type === "image") picS3FileData = elm;
        if (elm.type === "pdf") pdfS3FileData = elm;
      });
    }

    this.setState({
      // composedOfItems: obj.composedOfItems,
      compRawItemList: obj.composedOfItems,
      compositionCost: parseFloat(obj.compositionCost),
      compositionId: obj.compositionId,
      compositionName: obj.compositionName,
      fileUploads: obj.fileUploads,
      picS3FileData,
      pdfS3FileData,
      notes: obj.notes,
      s3bucket: obj.s3bucket,
      storeName: obj.storeName,
      selectedTargetItem: obj.target.itemObject,
      selectedSubItem: obj.target.subItemObject,
      showCompWipFlag: false,
      outputQty,
      expenseNote: obj.expenseNote,
      confirmToCompose,
      dasize,
    });
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
      okToCompose: false,
      confirmToCompose: true,
      iAgree: false,
    });
  };

  onChangeQty = async (e) => {
    let value = e.target.value;
    // console.log("in onChangeQty value:", value);
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
        errFlag: false,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
        errFlag: false,
      });
    }
    if (this.state.selectedTargetItem) {
      // console.log('244 value=', value);
      if (parseFloat(value) > 0) {
        this.setState({
          okToCompose: false,
          confirmToCompose: true,
          iAgree: false,
        });
      } else {
        this.setState({
          okToCompose: false,
          confirmToCompose: false,
          iAgree: false,
        });
      }
    }
  };

  onChangeCompCost = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
        errFlag: false,
        okToCompose: false,
        confirmToCompose: true,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
        errFlag: false,
        okToCompose: false,
        confirmToCompose: true,
      });
    }
    if (value > 0) {
      await this.setState({
        recepitButtonFlag: true,
      });
    } else {
      await this.setState({
        recepitButtonFlag: false,
      });
    }
  };

  onChangeUseQty = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  returnToInvMgmt = () => {
    if (this.state.fileUploads > 0) {
 
      const config = {
        bucketName: this.state.subDirName,
        dirName: this.state.subDirName,
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };
      this.state.fileUploads.forEach(async (obj) => {
        let delfilename = obj.key.split("/").pop();
        // console.log("delfilename:", delfilename);
        await ReactS3.deleteFile(delfilename, config);
        // console.log("handleDelete ret:", ret);
      });
    }
    this.props.returnToCaller();
  };

  getInventoryItems = async (filterType, calledBy) => {
    // console.log("filterType:", filterType, " calledBy:", calledBy);
    // console.log("this.state.searchItemName:", this.state.searchItemName);
    let filterName = "";
    if (calledBy === "target" && filterType === "composetarget")
      filterName = this.state.searchItemName;
    if ((calledBy = "build")) {
      if (filterType === "composeraw") {
        filterName = filterName = this.state.searchRawItem;
      }
      if (filterType === "composecatalog") {
        filterName = this.state.searchRawItem;
      }
    }

    // console.log("filterName:", filterName);
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&filterType=" +
      filterType +
      "&invClassification=" +
      this.state.invClassification +
      "&category=" +
      this.state.categorySelected +
      "&filterByName=" +
      filterName +
      "&itemType=goods" +
      "&baseItemType=" +
      this.state.bbItemType +
      "&rawNameType=" +
      this.state.itemNameType +
      "&calledBy=compose";

    let url = baandaServer + getItemsToActOn + params;
    // console.log("---- url:", url);
    try {
      let ret = await axios.get(url);
      // console.log("@@@@ items ret:", ret);
      if (ret.data.status === "success") {
        // console.log("in here 111 ret.data.Msg.length:", ret.data.Msg.length);
        if (ret.data.Msg.length === 0) {
          this.setState({
            errMsg: "No record was found with the filter condition.",
            errFlag: true,
          });
        } else {
          // console.log("filterType:", filterType);
          if (filterType === "composetarget") {
            this.setState({
              fetchedTargetItemFlag: true,
              targetItemList: ret.data.Msg,
              searchItemName: "",
              errMsg: "",
              errFlag: false,
            });
          }
          if (filterType === "composeraw" || filterType === "composecatalog") {
            this.setState({
              fetchedRawItemFlag: true,
              rawItemList: ret.data.Msg,
              searchItemName: "",
              errMsg: "",
              errFlag: false,
            });
          }
        }
      } else {
        this.setState({
          errMsg: ret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
    if (document.getElementById("itemSelected"))
      document.getElementById("itemSelected").value = "DEFAULT";
  };

  handleFilter = async () => {
    // console.log("handle Filter composetarget");
    await this.getInventoryItems("composetarget", "target");
  };

  handleAgreementConfirm = async () => {
    let okToCompose = false;
    if (this.state.compRawItemList.length > 0) {
      if (!this.state.iAgree) okToCompose = true;
      await this.setState((prevstate) => ({
        iAgree: !prevstate.iAgree,
        okToCompose,
        errMsg: "",
        errFlag: false,
      }));
    } else {
      await this.setState({
        errMsg: "Must have at least one raw item to compose with.",
        errFlag: true,
        okToCompose,
      });
    }
  };

  handleRawItemFilter = async () => {
    // console.log("Fetch raw item to compose 1");
    if (this.state.bbItemType === "raw")
      await this.getInventoryItems("composeraw", "build");
    if (this.state.bbItemType === "catalog")
      await this.getInventoryItems("composecatalog", "build");

    let crit = [];

    if (this.state.compRawItemList.length > 0) {
      this.state.rawItemList.forEach((obj) => {
        let robj = JSON.parse(JSON.stringify(obj));

        this.state.compRawItemList.forEach((elm) => {
          if (obj.itemName === elm.itemInBin.itemName) {
            robj.quantity = obj.quantity - elm.qtyUsed;
          }
        });
        crit.push(robj);
      });
    } else {
      if (this.state.rawItemList) crit = [...this.state.rawItemList];
    }

    await this.setState({
      rawItemList: crit,
    });
  };

  handleTargetListExit = async () => {
    await this.setState({
      fetchedTargetItemFlag: false,
    });
  };

  handleStorePanelExit = async () => {
    await this.setState({
      showStoreFlag: false,
    });
  };

  selectThisTargetItem = async (item) => {
    // console.log("item:", item);
    let showTargetSubItemFlag = false;
    let fetchedTargetItemFlag = false;
    let rawItemSelectedFlag = false;
    let selectedSubItem = null;

    if (item.itemVariance.length > 1) {
      showTargetSubItemFlag = true;
      fetchedTargetItemFlag = true;
    } else {
      selectedSubItem = item.itemVariance[0];
      rawItemSelectedFlag = false;
    }
    await this.setState({
      selectedSubItem,
      selectedTargetItem: item,
      showTargetSubItemFlag,
      searchItemName: item.itemName,
      fetchedTargetItemFlag,
      rawItemSelectedFlag,
    });
  };

  selectThisSubitem = async (subitem) => {
    // console.log("subitem:", subitem);
    await this.setState({
      selectedSubItem: subitem,
      fetchedTargetItemFlag: false,
      showTargetSubItemFlag: false,
    });
  };

  selectThisRawItem = async (rawitem) => {
    // console.log("rawitem:", rawitem);
    let subRawItemFlag = false;
    let subRawItemSelected = null;
    let selectedRawSubItem = null;
    let rawItemQty = 0;
    let rawItemSelectedFlag = false;
    let fetchedRawItemFlag = true;

    if (rawitem.itemVariance.length > 1) {
      // console.log("Here in 1111");
      subRawItemFlag = true;
    } else {
      subRawItemSelected = rawitem.itemVariance[0];
      selectedRawSubItem = rawitem.itemVariance[0];
      rawItemQty = subRawItemSelected.quantity;
      rawItemSelectedFlag = true;
      fetchedRawItemFlag = false;
    }
    this.setState({
      rawItemSelected: rawitem,
      rawItemQty,
      fetchedRawItemFlag,
      rawItemSelectedFlag,
      subRawItemFlag,
      subRawItemSelected,
      selectedRawSubItem,
    });
  };

  selectThisSubRawItem = async (rawSubItem) => {
    // console.log("rawSubItem:", rawSubItem);
    this.setState({
      fetchedRawItemFlag: false,
      rawItemSelectedFlag: true,
      subRawItemFlag: false,
      selectedRawSubItem: rawSubItem,
    });
  };

  handleSubitemPanelExit = async () => {
    await this.setState({
      showTargetSubItemFlag: false,
    });
  };

  handleRawitemPanelExit = async () => {
    await this.setState({
      fetchedRawItemFlag: false,
    });
  };

  handleSubRawitemPanelExit = async () => {
    await this.setState({
      subRawItemFlag: false,
    });
  };

  handleRawItemUsed = async () => {
    let cril = [...this.state.compRawItemList];
    // let seqno = cril.length;
    if (
      this.state.selectedRawSubItem.quantity < parseFloat(this.state.usedQty)
    ) {
      await this.setState({
        errMsg: "Used quantity cannot be more than in the stock.",
        errFlag: true,
      });
    } else {
      let newCompRow = {
        itemInBin: this.state.rawItemSelected,
        itemInSubBin: this.state.selectedRawSubItem,
        qtyUsed: parseFloat(this.state.usedQty),
      };
      cril.push(newCompRow);
      await this.setState({
        compRawItemList: cril,
        rawItemSelected: null,
        usedQty: 0,
        rawItemQty: 0,
        rawItemSelectedFlag: false,
        errMsg: "",
        errFlag: false,
      });
    }
  };

  handleItemRemove = async (obj) => {
    // console.log("remove obj:", obj);

    let cril = [...this.state.compRawItemList];
    let newcril = [];
    cril.forEach((elm) => {
      if (elm.seqno !== obj.seqno) newcril.push(elm);
    });

    if (newcril.length > 0) {
      let seqno = 0;
      newcril.forEach((llm) => {
        llm.seqno = seqno;
        seqno++;
      });
    }
    await this.setState({
      compRawItemList: newcril,
    });
  };

  // There is a faint possibility of subdirectory clash. Will not effect the logic.
  compositionSubDirectory = async () => {
    // console.log("compositionSubDirectory");
    let randomct, commrn, subBucket;
    if (this.state.subDirName === "" || this.state.subDirName === null) {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "itemmaster-cid" +
        this.props.communityid +
        "-target-" +
        this.state.selectedTargetItem.itemId +
        "-receipt-compose-" +
        commrn;
    } else subBucket = this.state.subDirName;

    // console.log("subBucket:", subBucket);
    await this.setState({
      subDirName: subBucket,
    });
    console.log("this.state.subDirName");
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  handleShowPdfFlag = async () => {
    // console.log("handleShowPdfFlag");
    // Get the right object from fileUploads to picS3FileData
    if (this.state.subDirName === "") this.compositionSubDirectory();
    await this.setState({
      itemComposeFlag: false,
      attachPdfFlag: true,
      attachPicFlag: false,
    });
  };

  handleShowImageFlag = async () => {
    // console.log("handleShowImageFlag");
    // Get the right object from fileUploads to picS3FileData
    if (this.state.subDirName === "") this.compositionSubDirectory();
    await this.setState({
      itemComposeFlag: false,
      attachPdfFlag: false,
      attachPicFlag: true,
    });
  };

  updateCommunityDA = async (newFile, oldFileSize) => {
    // console.log("newFile:", newFile, " oldFileSize:", oldFileSize);

    let newSize = 0;
    if (newFile) newSize = newFile.size;
    let input = {
      adjustOfDASize: parseFloat(newSize) - oldFileSize,
      communityId: this.props.communityId,
    };
    // console.log("input:", input);

    let isUpdated = true;

    try {
      let url = baandaServer + updateReceiptDA;
      let daret = await axios.post(url, input);
      if (daret.data.status === "error") {
        isUpdated = false;
        this.setState({
          errMsg: daret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      isUpdated = false;
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    // console.log("isUpdated:", isUpdated);

    return isUpdated;
  };

  returnToCompositionFromDA = async (fileData) => {
    // console.log("Check S3 fileData: ", fileData);

    let picS3FileData = this.state.picS3FileData;
    let pdfS3FileData = this.state.pdfS3FileData;
    let nfu = [];

    let updateDAFlag = false;
    let priorDASize = 0;

    if (fileData.ifDeletedFlag) {
      updateDAFlag = true;

      this.state.fileUploads.forEach((obj) => {
        priorDASize += obj.size;
        if (this.state.attachPdfFlag) {
          pdfS3FileData = null;
          if (obj.type !== "pdf") nfu.push(obj);
        }
        if (this.state.attachPicFlag) {
          if (obj.type !== "image") nfu.push(obj);
          picS3FileData = null;
        }
      });
    }

    if (fileData.s3FileData) {
      updateDAFlag = true;
      let fileType;

      if (this.state.attachPicFlag) {
        fileType = "image";
        picS3FileData = fileData.s3FileData;
      }

      if (this.state.attachPdfFlag) {
        fileType = "pdf";
        pdfS3FileData = fileData.s3FileData;
      }

      let receiptFile = {
        coopStoreId: this.props.coopStoreId,
        key: fileData.s3FileData.key,
        type: fileType,
        location: fileData.s3FileData.location,
        size: fileData.s3FileData.size,
      };
      nfu.push(receiptFile);
    }

    if (updateDAFlag) {
      await this.updateCommunityDA(fileData.s3FileData, priorDASize);
    }

    await this.setState({
      itemComposeFlag: true,
      attachPdfFlag: false,
      attachPicFlag: false,
      fileUploads: nfu,
      picS3FileData,
      pdfS3FileData,
    });
  };

  composeNewItem = async () => {
    // console.log("in composeNewItem...");
    let check = await this.validateCompEntries();
    if (check.status === "success") {
      let inputData = await this.compLogForDB("composed");

      // console.log("composeNewItem inputData:", inputData);
      let url = baandaServer + saveComposedItem;
      try {
        let compret = await axios.post(url, inputData);
        // console.log("compret:", compret);
        if (compret.data.status === "success") {
          this.returnAfterCompose();
        } else {
          this.setState({
            errMsg: compret.data.Msg,
            errFlag: true,
          });
        }
        // Handle Compose ... may go back with this.state.outputQty to
        // show the increased ... or all the way to InventoryMgmt.
      } catch (err) {
        // console.log("Err:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: check.Msg,
        errFlag: true,
      });
    }
  };

  returnAfterCompose = async () => {
    await this.setState({
      targetUnitName: "",
      searchItemName: "",
      selectedItemName: "",
      outputQty: 0,
      compositionCost: 0,

      invClassification: "",
      categorySelected: "",

      fetchedTargetItemFlag: false,
      targetItemList: null,

      selectedTargetItem: null,
      showTargetSubItemFlag: false,
      selectedSubItem: null,

      searchRawItem: "",

      rawItemList: null,
      compRawItemList: [],
      rawItemSelected: null,
      rawItemSelectedFlag: false,
      usedQty: 0,

      errMsg: "Successfully Composed",
      errFlag: false,

      iAgree: false,

      confirmToCompose: false,
      okToCompose: false,

      subDirName: "",
      itemComposeFlag: true,
      attachPicFlag: false,
      attachPdfFlag: true,
      picS3FileData: null,
      pdfS3FileData: null,
      fileUploads: [],

      paymentMedium: "cash",
      expenseNote: "",
    });
  };

  handlePaymentMedium = async (e) => {
    await this.setState({
      paymentMedium: e.target.value,
    });
  };

  handlebbItemType = async (e) => {
    await this.setState({
      bbItemType: e.target.value,
    });
  };

  handleItemNameType = async (e) => {
    await this.setState({
      itemNameType: e.target.value,
    });
  };

  handleStoreType = async (e) => {
    let showStoreFlag = false;
    if (e.target.value === "store") showStoreFlag = true;
    await this.setState({
      storeType: e.target.value,
      showStoreFlag,
    });
  };

  readWriteAComment = async () => {
    this.setState({
      noteMgmtFlag: true,
    });
  };

  closeNotes = async () => {
    this.setState({
      noteMgmtFlag: false,
    });
  };

  compLogForDB = async (calledBy) => {
    let size = 0;
    this.state.fileUploads.forEach((obj) => {
      size = size + obj.size;
    });

    let target = {
      itemId: 0,
      itemObject: null,
      subItemObject: null,
      outputQty: 0,
    };
    if (this.state.selectedTargetItem) {
      // console.log("in selectedTargetItem ...");
      target.itemId = this.state.selectedTargetItem.itemId;
      target.itemObject = this.state.selectedTargetItem;
      target.outputQty = parseFloat(this.state.outputQty);
    }
    if (this.state.selectedSubItem) {
      target.subItemObject = this.state.selectedSubItem;
    }

    // console.log("target:", target);

    // let storeName = "";
    // if (this.state.selectedStore)
    //   storeName = this.state.selectedStore.storeName;

    let input = {
      compositionId: this.state.compositionId,
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      compositionName: this.state.compositionName,
      target,
      expenseNote: this.state.expenseNote,
      fileUploads: this.state.fileUploads,
      composedOfItems: this.state.compRawItemList,
      s3bucket: this.state.s3bucket,
      wip_by_baandaId: this.props.auth.user.baandaId,
      compositionCost: parseFloat(this.state.compositionCost),
      notes: this.state.notes,
      updated_at: Date.now(),
      status: calledBy,
      refInventoryId: 0,
      refExpenseId: 0,
    };

    // console.log("input:", input);

    return input;
  };

  validateForFinalComposition = async () => {
    let msg = "";
    let error = "success";
    if (this.state.compositionName === "") {
      msg = "Must have a cmoposition Name. ";
      error = "error";
    }

    if (this.state.selectedTargetItem) {
      if (this.state.compRawItemList.length > 0) {
        this.state.compRawItemList.forEach((cii) => {
          if (cii.itemInBin.itemId === this.state.selectedTargetItem.itemId) {
            msg += "Target item should not be one of the build items.";
            error = "error";
          }
        });
      }
    } else {
      msg += "Target item must be selected to compose. ";
      error = "error";
    }

    if (this.state.outputQty === 0) {
      msg += "Number of composed quantity must be non-zero. ";
      error = "error";
    }

    if (this.state.compositionCost > 0 && this.state.expenseNote === "") {
      msg += "There must be an expense note for the composition cost. ";
      error = "error";
    }

    return { status: error, Msg: msg };
  };

  validateCompEntries = async () => {
    let msg = "";
    let error = "success";
    if (this.state.compositionName === "") {
      msg = "Must have a cmoposition Name; ";
      error = "error";
    }

    if (this.state.selectedTargetItem) {
      if (this.state.compRawItemList.length > 0) {
        this.state.compRawItemList.forEach((cii) => {
          if (cii.itemInBin.itemId === this.state.selectedTargetItem.itemId) {
            msg += "Target item should not be one of the build items; ";
            error = "error";
          }
        });
      }
    }

    if (parseFloat(this.state.outputQty) <= 0) {
      msg += "Output quantity must be a positive number; ";
      error = "error";
    }

    return { status: error, Msg: msg };
  };

  validateForDraftComposition = async () => {
    let msg = "";
    let state = "success";
    if (this.state.compositionName === "") {
      msg = "Must have a cmoposition Name. ";
      state = "error";
    }
    return { status: state, Msg: msg };
  };

  saveComposeAsDraft = async () => {
    let check = await this.validateForDraftComposition();

    if (check.status === "success") {
      let input = await this.compLogForDB("wip");
      let url = baandaServer + saveCompositionWip;
      // console.log("url:", url, " input:", input);
      try {
        let wipret = await axios.post(url, input);
        // console.log("wipret.data:", wipret.data);
        if (wipret.data.status === "success") {
          this.setState({
            errMsg: "Saved Successfully",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: wipret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: check.Msg,
        errFlag: true,
      });
    }
  };

  addNote = () => {
    let now = new Date().toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    // console.log("now:", now);
    let note = [...this.state.notes];
    let inp = {
      baandaId: this.props.auth.user.baandaId,
      name: this.props.auth.user.name,
      email: this.props.auth.user.email,
      noteText: this.state.noteText,
      commentedOn: now,
    };
    note.push(inp);
    this.setState({
      notes: note,
    });
  };

  getCompositionWIP = async () => {
    // let store = "all";
    // if (this.state.selectedStore) store = this.state.selectedStore.storeName;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&status=wip";
    let url = baandaServer + getCompositionWIP + param;
    try {
      let wipret = await axios.get(url);
      // console.log("wipret.data:", wipret.data);
      if (wipret.data.status === "success") {
        this.setState({
          compwip: wipret.data.Msg,
          showCompWipFlag: true,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: wipret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleWipPanelExit = async () => {
    this.setState({
      showCompWipFlag: false,
    });
  };

  openWip = async () => {
    await this.getCompositionWIP();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ItemComposition...");

    // let categoryBtn;
    // categoryBtn = (
    //   <button
    //     className="btn_reg_70"
    //     type="button"
    //     onClick={this.handleCategory}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     Category
    //   </button>
    // );

    let buttonPanel = (
      <div className="row">
        <div className="col-6 text-center comp-name-col">
          Name&nbsp;
          <input
            name="compositionName"
            type="text"
            value={this.state.compositionName}
            onChange={this.onChange}
            size="30"
            maxLength="50"
            className="composition-name"
            placeholder="Enter unique composition name"
            spellCheck="false"
          />{" "}
          &nbsp;
          <button
            onClick={this.saveComposeAsDraft}
            className="btn_reg_60"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Draft
          </button>
          <button
            onClick={this.openWip}
            className="btn_reg_60"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Open
          </button>
        </div>
        <div className="col-6 text-center compose-button-row-pos">
          {this.state.confirmToCompose ? (
            <Checkbox
              checked={this.state.iAgree}
              onChange={this.handleAgreementConfirm}
            />
          ) : null}
          &nbsp;
          {this.state.confirmToCompose ? " Confirm. " : null}
          {this.state.okToCompose > 0 ? (
            <button
              onClick={this.composeNewItem}
              className="btn_reg_80"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Compose
            </button>
          ) : null}
          <button
            onClick={this.readWriteAComment}
            className="btn_reg_60"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Note
          </button>
          <button
            onClick={this.returnToInvMgmt}
            className="btn_back_main"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>
          <button
            className="btn_info_main"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-info-circle" />
          </button>
          <button
            onClick={this.deleteThisWip}
            className="btn_exit_del"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      </div>
    );

    let fetchBtnPanel;
    fetchBtnPanel = (
      <button
        className="btn_reg_60"
        onClick={this.handleFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch
      </button>
    );

    let fetchRawBtnPanel;
    fetchRawBtnPanel = (
      <button
        className="btn_reg_60"
        onClick={this.handleRawItemFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch
      </button>
    );

    let exitTargetListPanel;
    exitTargetListPanel = (
      <button
        className="btn_back_main"
        onClick={this.handleTargetListExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitSubitemPanel;
    exitSubitemPanel = (
      <button
        className="btn_back_main"
        onClick={this.handleSubitemPanelExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitRawListPanel;
    exitRawListPanel = (
      <button
        className="btn_back_main"
        onClick={this.handleRawitemPanelExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitSubRawListPanel;
    exitSubRawListPanel = (
      <button
        className="btn_back_main"
        onClick={this.handleSubRawitemPanelExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitWipPanel;
    exitWipPanel = (
      <button
        className="btn_back_main"
        onClick={this.handleWipPanelExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let openCompWipPanel;
    if (this.state.showCompWipFlag) {
      if (this.state.compwip.length > 0) {
        let bool5 = true;
        openCompWipPanel = (
          <div className="select-wip-box">
            <div className="text-center wip-comp-header">
              Existing WIP {exitWipPanel}
            </div>
            <div className="text-center wip-no-rec-found">
              <div className="fixedsize-select-subitem">
                {this.state.compwip.map((obj, i) => {
                  return (
                    <div key={i}>
                      <div
                        className={
                          bool5
                            ? "row row-col-schdule-dr"
                            : "row row-col-schdule-lt"
                        }
                      >
                        <div className="col-2 text-center">
                          <button
                            className="btn_reg_40"
                            type="button"
                            onClick={() => this.selectThisComposition(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-check" />
                          </button>
                        </div>

                        <div className="col-10 text-left item-category-label">
                          {obj.compositionName}
                        </div>
                      </div>
                      {(bool5 = !bool5)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else {
        openCompWipPanel = (
          <div className="select-wip-box">
            <div className="text-center wip-comp-header">
              Existing WIP {exitWipPanel}
            </div>
            <div className="text-center wip-no-rec-found">
              No work-in-progress exists
            </div>
          </div>
        );
      }
    }

    let bool = true;
    let subItemPanel;
    if (this.state.showTargetSubItemFlag) {
      subItemPanel = (
        <div>
          <div className="select-target-subitem-box">
            <div className="text-center inventory-label">
              Select a target subItem&nbsp;&nbsp;{exitSubitemPanel}
            </div>
            <div className="fixedsize-select-subitem">
              {this.state.selectedTargetItem.itemVariance.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-2 text-center">
                        <button
                          className="btn_reg_30"
                          type="button"
                          onClick={() => this.selectThisSubitem(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>

                      <div className="col-10 text-left item-category-label">
                        {obj.name}&nbsp;[{obj.options}&nbsp;|| Qty:
                        {obj.quantity}]
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    // let selectAStorePanel;
    // let bool4 = true;
    // if (this.state.showStoreFlag) {
    //   selectAStorePanel = (
    //     <div>
    //       <div className="select-target-item-box">
    //         <div className="text-center inventory-label">
    //           Select a store&nbsp;&nbsp;{exitStorePanel}
    //         </div>
    //         <div className="fixedsize-select-item">
    //           {this.state.stores.map((obj, i) => {
    //             return (
    //               <div key={i}>
    //                 <div
    //                   className={
    //                     bool4
    //                       ? "row row-col-schdule-dr"
    //                       : "row row-col-schdule-lt"
    //                   }
    //                 >
    //                   <div className="col-2 text-center">
    //                     <button
    //                       className="btn-comp-target-check"
    //                       type="button"
    //                       onClick={() => this.selectThisStore(obj)}
    //                       style={{
    //                         cursor: this.state.disabled ? "default" : "pointer",
    //                       }}
    //                     >
    //                       <i className="fa fa-check" />
    //                     </button>
    //                   </div>

    //                   <div className="col-10 text-left item-category-label">
    //                     {obj.storeName}
    //                   </div>
    //                 </div>
    //                 {(bool4 = !bool4)}
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let bool1 = true;
    let toSelectItemFromPanel;
    if (this.state.fetchedTargetItemFlag) {
      toSelectItemFromPanel = (
        <div>
          <div className="select-target-item-box">
            <div className="text-center inventory-label">
              Select Target Item&nbsp;&nbsp;{exitTargetListPanel}
            </div>
            <div className="fixedsize-select-item">
              {this.state.targetItemList.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-2 text-center">
                        <button
                          className="btn_reg_40"
                          type="button"
                          onClick={() => this.selectThisTargetItem(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>

                      <div className="col-10 text-left item-category-label">
                        {obj.itemName}&nbsp;[Type:{obj.categoryOf}; Curr-Qty:
                        {obj.quantity}]
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
          {subItemPanel}
        </div>
      );
    }

    let toSelectRawItemFromPanel;
    if (this.state.fetchedRawItemFlag) {
      toSelectRawItemFromPanel = (
        <div>
          <div className="select-target-item-box">
            <div className="text-center inventory-label">
              Build with Items&nbsp;&nbsp;{exitRawListPanel}
            </div>
            <div className="fixedsize-select-item">
              {this.state.rawItemList.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-2 text-center">
                        <button
                          className="btn_reg_40"
                          type="button"
                          onClick={() => this.selectThisRawItem(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>

                      <div className="col-10 text-left item-category-label">
                        {obj.itemName}&nbsp;; Curr-Qty:
                        {obj.quantity}]
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
          {subItemPanel}
        </div>
      );
    }

    // let storePanel;
    // if (this.state.stores && this.state.stores.length === 0) {
    //   storePanel = (
    //     <div className="text-center store-select-line">
    //       Select a Store:&nbsp;
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="all"
    //             checked={this.state.storeType === "all"}
    //             onChange={this.handleStoreType}
    //           />{" "}
    //           All
    //         </label>
    //       </div>
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="store"
    //             checked={this.state.storeType === "store"}
    //             onChange={this.handleStoreType}
    //           />{" "}
    //           A Store
    //         </label>
    //       </div>
    //       {selectAStorePanel}
    //     </div>
    //   );
    // }

    let targetNamePanel;
    targetNamePanel = (
      <div>
        {/* {storePanel} */}
        <div className="row comp-name-row">
          <div className="col-3 text-right inventory-label">Item Name</div>
          <div className="col-9 input_placement text-left">
            <input
              name="searchItemName"
              type="text"
              value={this.state.searchItemName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_text_catlog_target"
              placeholder="Enter item name to filter"
              spellCheck="false"
            />
            &nbsp;&nbsp;{fetchBtnPanel}
          </div>
        </div>
        <div className="row">
          <div className="col-2">&nbsp;</div>
          <div className="col-6 text-center comp-name-msg">
            Part/full of item name.
          </div>
          <div className="col-4">&nbsp;</div>
        </div>
        {toSelectItemFromPanel}
      </div>
    );

    let rawNamePanel;
    rawNamePanel = (
      <div>
        <div className="row comp-name-row">
          <div className="col-2 text-right inventory-label">Build Item</div>
          <div className="col-6 input_placement text-left">
            <input
              name="searchRawItem"
              type="text"
              value={this.state.searchRawItem}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_text_catlog_build"
              placeholder="Enter item name to filter"
              spellCheck="false"
            />
            &nbsp;&nbsp;{fetchRawBtnPanel}
          </div>
          <div className="col-4 text-right">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col-3">&nbsp;</div>
          <div className="col-6 text-left comp-name-msg">
            Part/full of item name.
          </div>
          <div className="col-3">&nbsp;</div>
        </div>
        {toSelectRawItemFromPanel}
      </div>
    );

    let rawSubNamePanel;
    if (this.state.subRawItemFlag) {
      rawSubNamePanel = (
        <div>
          <div className="select-target-subitem-box">
            <div className="text-center inventory-label">
              Build Sub-item&nbsp;&nbsp;{exitSubRawListPanel}
            </div>
            <div className="fixedsize-select-subitem">
              {this.state.rawItemSelected.itemVariance.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-2 text-center">
                        {obj.deleted ? (
                          <button
                            className="btn-comp-target-check-disabled"
                            type="button"
                          >
                            N/A
                          </button>
                        ) : (
                          <button
                            className="btn-comp-target-check"
                            type="button"
                            onClick={() => this.selectThisSubRawItem(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-check" />
                          </button>
                        )}
                      </div>

                      <div className="col-10 text-left item-category-label">
                        {obj.name}&nbsp;
                        {obj.options !== ""
                          ? "(" + obj.options + ") "
                          : " "}{" "}
                        {obj.deleted
                          ? "Deleted/disabled"
                          : "Curr-Qty:" + obj.quantity}
                        {/* Curr-Qty:
                        {obj.quantity} */}
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
          {subItemPanel}
        </div>
      );
    }

    let topInputPanel;
    if (this.state.selectedTargetItem) {
      topInputPanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right inventory-label">Output Qty</div>
            <div className="col-1 text-left comp-input-lbl">
              <input
                name="outputQty"
                type="number"
                value={this.state.outputQty}
                onChange={this.onChangeQty}
                size="20"
                className="inv-comp-out-qty"
                step="1"
                placeholder="0"
                autoComplete="off"
              />
            </div>
            <div className="col-4 text-left comp-output-goods-txt">
              {this.state.selectedTargetItem
                ? this.state.selectedTargetItem.itemName
                : ""}
              {this.state.selectedSubItem
                ? " [" +
                  this.state.selectedSubItem.name +
                  " (" +
                  this.state.selectedSubItem.options +
                  ")]"
                : null}
              &nbsp;&nbsp;
              {this.state.selectedTargetItem
                ? " Unit: " + this.state.selectedTargetItem.unitName
                : null}
            </div>
            <div className="col-4 text-left comp-output-goods-txt">
              {this.state.selectedTargetItem
                ? "Catgory:" + this.state.selectedTargetItem.category
                : ""}
            </div>
          </div>
          <div className="row comp-row-pos">
            <div className="col-3 text-right inventory-label">
              Composing Cost $
            </div>
            <div className="col-9 text-left left comp-input-lbl">
              <input
                name="compositionCost"
                type="number"
                value={this.state.compositionCost}
                onChange={this.onChangeCompCost}
                size="20"
                className="inv-comp-cost-amt"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
              />
              &nbsp;&nbsp;&nbsp;
              <strong>Pay Medium&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="cash"
                    checked={this.state.paymentMedium === "cash"}
                    onChange={this.handlePaymentMedium}
                  />{" "}
                  Cash
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="check"
                    checked={this.state.paymentMedium === "check"}
                    onChange={this.handlePaymentMedium}
                  />{" "}
                  Check
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="creditcard"
                    checked={this.state.paymentMedium === "creditcard"}
                    onChange={this.handlePaymentMedium}
                  />{" "}
                  CC
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="crypto"
                    checked={this.state.paymentMedium === "crypto"}
                    onChange={this.handlePaymentMedium}
                    disabled
                  />{" "}
                  Crypto
                </label>
              </div>
            </div>
          </div>
          {this.state.compositionCost > 0 ? (
            <div className="row comp-row-pos">
              <div className="col-3 text-right inventory-label">
                Load Receipt
              </div>
              <div className="col-9 text-left left comp-input-lbl">
                <button
                  onClick={this.handleShowImageFlag}
                  className="btn_reg_70"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Image
                </button>
                <button
                  onClick={this.handleShowPdfFlag}
                  className="btn_reg_40"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Pdf
                </button>
                &nbsp;&nbsp;<b>Note</b>&nbsp;
                <input
                  name="expenseNote"
                  type="text"
                  value={this.state.expenseNote}
                  onChange={this.onChange}
                  size="100"
                  maxLength="100"
                  className="composition-cost-note"
                  placeholder="Reference note. Payment is assumed to be complete."
                  spellCheck="true"
                />
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let topPanel = (
      <div className="text-center">
        {targetNamePanel}
        {topInputPanel}
      </div>
    );

    let getRawItemForComp;
    getRawItemForComp = (
      <div>
        <div className="row qty-to-use-row">
          <div className="col-3 text-right inventory-label">Qty to use:</div>
          <div className="col-3 text-left">
            <input
              name="usedQty"
              type="number"
              value={this.state.usedQty}
              onChange={this.onChangeUseQty}
              size="20"
              className="comp-use-qty"
              step="1"
              placeholder="0"
              autoComplete="off"
            />
          </div>
          <div className="col-6 text-center">
            {this.state.usedQty > 0 ? (
              <button
                className="btn_reg_70"
                onClick={this.handleRawItemUsed}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;
                <i className="fa fa-plus" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );

    let rawItemOpspanel;
    if (this.state.rawItemSelectedFlag) {
      rawItemOpspanel = (
        <div>
          <div className="row raw-item-disp-row">
            <div className="col-1 text-right inventory-label">Selected</div>
            <div className="col-11 text-left inventory-label">
              {this.state.rawItemSelected.itemName}&nbsp; (
              {this.state.selectedRawSubItem
                ? this.state.selectedRawSubItem.name
                : ""}{" "}
              -{" "}
              {this.state.selectedRawSubItem
                ? this.state.selectedRawSubItem.options
                : ""}
              ) [Qty in stock:
              {this.state.selectedRawSubItem
                ? this.state.selectedRawSubItem.quantity
                : "0"}
              ] - {this.state.rawItemSelected.unitName}
            </div>
          </div>
          {this.state.selectedRawSubItem &&
          this.state.selectedRawSubItem.quantity > 0 ? (
            getRawItemForComp
          ) : (
            <div className="no-inventory-to-use">
              No inventory available. Stock it up to compose.
            </div>
          )}
        </div>
      );
    }

    let rawSelectPanel;
    rawSelectPanel = (
      <div className="text-center">
        {rawNamePanel}
        {rawItemOpspanel}
        {rawSubNamePanel}
      </div>
    );

    let headerPanel;
    headerPanel = (
      <div className="inv-compose-box">
        <div className="target-header text-center">Composition Target</div>
        {topPanel}
      </div>
    );

    let compItemTypePanel;
    compItemTypePanel = (
      <div>
        <div className="row">
          <div className="col-6 text-center target-header">
            Building Block Items
          </div>
          <div className="col-6 text-right bb-item-types">
            <div className="form-check form-check-inline">Item Type: </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="raw"
                  checked={this.state.bbItemType === "raw"}
                  onChange={this.handlebbItemType}
                />{" "}
                Raw
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="catalog"
                  checked={this.state.bbItemType === "catalog"}
                  onChange={this.handlebbItemType}
                />{" "}
                Catalog
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let itemSelectPanel = (
      <div className="inv-item-select-box">
        {compItemTypePanel}
        {rawSelectPanel}
      </div>
    );

    let selectHeadersPanel = (
      <div className="text-center select-panels-header">
        <div className="row">
          <div className="col-1 text-center">Ops</div>
          <div className="col-1 text-center">Qty</div>
          <div className="col-1 text-center">Unit</div>
          <div className="col-9 text-left">Item Name</div>
        </div>
      </div>
    );

    let bool2 = true;
    let compItemsPanel;
    compItemsPanel = (
      <div>
        <div className="fixedsize-select-item">
          {this.state.compRawItemList.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    // bool2 ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    bool2 ? "row row-col-compose-dr" : "row row-col-compose-lt"
                  }
                >
                  <div className="col-1 text-center">
                    <button
                      className="btn_exit_del"
                      type="button"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      onClick={() => this.handleItemRemove(obj)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </div>
                  <div className="col-1 text-center raw-in-comp-val">
                    {obj.qtyUsed}
                  </div>
                  <div className="col-1 text-center raw-in-comp-val">
                    {obj.itemInBin.unitName}
                  </div>
                  <div className="col-9 text-left raw-in-comp-val">
                    {obj.itemInBin.itemName} {obj.itemInSubBin.name} (
                    {obj.itemInSubBin.options})
                  </div>
                </div>
                {(bool2 = !bool2)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let selectedItemsPanel = (
      <div className="fixedsize-comp-item-list">{compItemsPanel}</div>
    );

    let outputPanel;
    if (this.state.attachPicFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToCompositionFromDA}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="Item Composition (Image)"
            s3FileData={this.state.picS3FileData}
            type="image"
          />
        </div>
      );
    }

    let noteMgmtPanel;
    if (this.state.noteMgmtFlag) {
      let bool3 = true;
      noteMgmtPanel = (
        <div className="item-composition-note-box">
          <div className="text-center item-note-head">
            Notes &nbsp;&nbsp;
            <button
              className="btn_back_main"
              onClick={this.closeNotes}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row">
            <div className="col-1">&nbsp;</div>
            <div className="col-10 text-left">
              <textarea
                name="noteText"
                maxLength="200"
                placeholder="Your notes ... "
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="comp-note-textarea"
                onChange={this.onChange}
                value={this.state.noteText}
                onKeyPress={(event) => event.key === "Enter" && this.addNote()}
              />
            </div>
            <div className="col-1 text-left">
              <button
                className="btn_reg_40"
                onClick={this.addNote}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="fixedsize-select-item">
            {this.state.notes.map((obj, i) => {
              // console.log("obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool3
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col">
                      <div className="text-left comp-note-names">
                        {obj.name} | {obj.email} | {obj.commentedOn}
                      </div>
                      <div className="text-left comp-note-text">
                        {obj.noteText}
                      </div>
                    </div>
                  </div>
                  {(bool3 = !bool3)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (this.state.attachPdfFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToCompositionFromDA}
            communityId={this.props.communityId}
            fileType={pdfTypes}
            maxSize={maxPdfSize}
            s3subdir={this.state.subDirName}
            requestor="Item Composition (PDF)"
            s3FileData={this.state.pdfS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    if (this.state.itemComposeFlag) {
      outputPanel = (
        <div className="item-composition-box">
          {headerPanel}
          {itemSelectPanel}
          {noteMgmtPanel}
          {openCompWipPanel}
          {selectHeadersPanel}
          {selectedItemsPanel}

          {buttonPanel}
          {this.state.errFlag ? (
            <div className="text-center composition-msg-err">
              {this.state.errMsg}
            </div>
          ) : (
            <div className="text-center composition-msg">
              {this.state.errMsg}
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ItemComposition.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ItemComposition));
