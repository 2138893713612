import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactS3 from "react-s3";
import axios from "axios";

import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./CreateSubItems.css";

const picTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const maxPicSize = 52428800; // 50MB

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateSubItems = "/routes/dashboard/updateSubItems";
const getAnItemFromIM = "/routes/dashboard/getAnItem?";
const getItemToEdit = "/routes/dashboard/getItemToEdit?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111121100";

class CreateSubItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      seqno: 0,
      subItemName: "",
      subItemOption: "",

      inCatalog: false,
      image: { key: "", type: "", location: "" },
      opsState: true,
      quantity: 0,
      lastcost: 0,
      movingAvgCost: 0,

      subitems: [],

      editFlag: false,
      itemVariationFlag: true,

      attachPicFlag: false,
      subDirName: "",
      picS3FileData: { key: "", type: "", location: "" },

      errMsg: "",
      errFlag: false,
      attachImageflag: false,
      deletedDASize: 0,
      adjustOfDASize: 0,

      strangexx: 0,
      catalog: null,
      // itemIM: null,
      selectedToEdit: null,

      isNewSubItem: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let subitems = [];

    let subDirName = this.state.subDirName;
    if (this.props.item) subDirName = this.props.item.subBucket;

    await this.getCatalogItem();

    if (
      this.props.item.itemVariance &&
      this.props.item.itemVariance.length > 0
    ) {
      if (this.state.catalog && this.state.catalog.itemVariance.length > 0) {
        this.props.item.itemVariance.forEach((elm1) => {
          let canDelete = true;
          let inCatalog = false;
          if (elm1.seqno === 1) {
            // This is the main item ...
            canDelete = false;
          } else {
            this.state.catalog.itemVariance.forEach((obj) => {
              if (obj.seqno === elm1.seqno) {
                inCatalog = true;
                if (obj.quantity > 0 || elm1.quantity > 0) canDelete = false;
                // if (obj.quantity > 0 ) canDelete = false;
              }
            });
          }

          elm1.canDelete = canDelete;
          elm1.inCatalog = inCatalog;
          subitems.push(elm1);
        });
      } else {
        this.props.item.itemVariance.forEach((elm2) => {
          elm2.inCatalog = false;
          elm2.canDelete = true;
          subitems.push(elm2);
        });
      }
    } else {
      let sobj = {
        seqno: 0,
        name: this.props.item.itemName,
        options: "",
        quantity: 0,
        lastcost: 0,
        movingAvgCost: 0,
        image: this.state.image,
        state: true,
        inCatalog: false,
      };
      subitems.push(sobj);
    }

    await this.setState({
      deviceSize,
      subitems,
      subDirName,
    });
  };

  getCatalogItem = async () => {
    let param =
      "communityId=" +
      this.props.communityId +
      "&itemId=" +
      this.props.item.itemId;
    let url = baandaServer + getItemToEdit + param;
    // console.log("xx getCatalogItem url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("---->> catret:", catret);
      if (catret.data.Msg.length > 0) {
        await this.setState({
          catalog: catret.data.Msg[0],
        });
      } else {
        await this.setState({
          catalog: null,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  getAnItemFromItemMaster = async () => {
    let param =
      "requestBy=itemId&itemId=" +
      this.props.item.itemId +
      "&clientProgram=AnItemsInventory&clientFunction=getAnItemFromItemMaster";
    try {
      let url = baandaServer + getAnItemFromIM + param;
      // console.log("url:", url);
      let itret = await axios.get(url);
      // console.log("itret:", itret);
      if (itret.data.status === "success") {
        this.setState({
          errorPanelFlag: false,
          errorMsg: "",
          item: itret.data.Msg[0],
        });
      } else {
        this.setState({
          errorPanelFlag: true,
          errorMsg: itret.Msg,
        });
      }
    } catch (err) {
      console.log("getAnItemFromItemMaster err:", err.message);
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToAnItemsInventory = async () => {
    this.props.returnToCaller(this.state.isNewSubItem);
  };

  saveValidate = async (itlist) => {
    let itExists = false;

    itlist.forEach((obj) => {
      if (
        this.state.subItemName
          .toLocaleLowerCase()
          .replace(/\s+/g, " ")
          .trim() ===
          obj.name.toLocaleLowerCase().replace(/\s+/g, " ").trim() &&
        this.state.subItemOption
          .toLocaleLowerCase()
          .replace(/\s+/g, " ")
          .trim() ===
          obj.options.toLocaleLowerCase().replace(/\s+/g, " ").trim()
      ) {
        if (!this.state.editFlag) itExists = true;
        else {
          if (obj.seqno !== this.state.seqno) {
            itExists = true;
          }
        }
      }
    });

    return itExists;
  };

  justSaveSub = async () => {
    let itlist = [...this.state.subitems];
    let itExists = await this.saveValidate(itlist);
    // console.log("itExists:", itExists);
    let ltemp = [];
    let sobj;
    let newseqno = itlist.length;
    if (!itExists) {
      // console.log("this.state.editFlag:", this.state.editFlag);

      if (this.state.editFlag) {
        // Edit PAth ...
        itlist.forEach((elm) => {
          if (elm.seqno !== this.state.seqno) ltemp.push(elm);
        });
        // console.log("this.state.selectedToEdit:", this.state.selectedToEdit);
        let deleted = false;
        if (this.state.selectedToEdit)
          deleted = this.state.selectedToEdit.deleted;
        sobj = {
          seqno: this.state.seqno,
          name: this.state.subItemName,
          options: this.state.subItemOption,
          quantity: this.state.selectedToEdit.quantity,
          lastcost: this.state.selectedToEdit.lastcost,
          movingAvgCost: this.state.selectedToEdit.movingAvgCost,
          image: this.state.selectedToEdit.image,
          state: this.state.selectedToEdit.opsState,
          inCatalog: this.state.inCatalog,
          deleted,
        };
        // console.log("sobj:", sobj);
        ltemp.push(sobj);
      } else {
        // New  Path
        sobj = {
          seqno: newseqno,
          name: this.state.subItemName,
          options: this.state.subItemOption,
          quantity: 0,
          lastcost: 0,
          movingAvgCost: 0,
          image: this.state.image,
          state: true,
          inCatalog: false,
          deleted: false,
        };
        ltemp = [...itlist];
        ltemp.push(sobj);
      }
    }

    if (itExists) {
      await this.setState({
        errMsg: "Duplicate Sub Name & Options is not allowed.",
        errFlag: true,
      });
    } else {
      await this.saveItInDB(ltemp);
    }
  };

  handleClearEdit = async () => {
    await this.setState({
      selectedToEdit: null,
      subItemName: "",
      subItemOption: "",
      attachImageflag: false,
      picS3FileData: { key: "", type: "", location: "" },
      image: { key: "", type: "", location: "" },
    });
  };

  saveItInDB = async (ltemp) => {
    // Save it in database.
    let input = {
      itemId: this.props.item.itemId,
      itemVariance: ltemp,
      subBucket: this.state.subDirName,
      type: "createupdate",
      communityId: this.props.communityId,
      adjustOfDASize: this.state.adjustOfDASize,
    };
    // console.log("(264) for db input:", input);
    try {
      let url = baandaServer + updateSubItems;
      let retsub = await axios.post(url, input);
      // console.log("retsub.data:", retsub.data);
      if (retsub.data.status === "success") {
        await this.setState({
          errMsg: "Updated successfuly",
          errFlag: false,
          subitems: input.itemVariance,
          // subitems: retsub.data.Msg,
          isNewSubItem: true,
          seqno: 2,
          subItemName: "",
          subItemOption: "",
          inCatalog: false,
          image: { key: "", type: "", location: "" },
          opsState: true,
          quantity: 0,
          lastcost: 0,
          movingAvgCost: 0,
          editFlag: false,
          attachImageflag: false,
          adjustOfDASize: 0,
          deletedDASize: 0,
          selectedToEdit: null,
        });

      } else {
        await this.setState({
          errMsg: retsub.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  selectThisItemToedit = async (sobj) => {
    // console.log("sobj:", sobj);
    await this.setState({
      seqno: sobj.seqno,
      subItemName: sobj.name,
      subItemOption: sobj.options,
      image: sobj.image,
      editFlag: true,
      selectedToEdit: sobj,
    });
  };

  selectThisItemToDelete = async (dobj) => {
    // console.log("dobj:", dobj);
    let ltemp = [];
    let itlist = [...this.state.subitems];
    let dasize = 0;

    for (var j = 0; j < itlist.length; j++) {
      let elm = itlist[j];
      if (elm.seqno !== dobj.seqno) {
        ltemp.push(elm);
      } else {
        dasize = elm.image.size * -1;
        elm.deleted = true;
        ltemp.push(elm);
      }
    }

    await this.setState({
      subitems: ltemp,
      adjustOfDASize: dasize,
    });
    await this.saveItInDB(ltemp);
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  subItemSubDirectory = async () => {
    // console.log("in subItemSubDirectory ...");
    let randomct, commrn, subBucket;
    if (this.state.subDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "item-cid" +
        this.props.communityId +
        "-itemId" +
        this.props.item.itemId +
        "-image-" +
        commrn;
    } else subBucket = this.state.subDirName;

    // console.log("subSucket:", subBucket);
    await this.setState({
      subDirName: subBucket,
    });
  };

  getImageAndSave = async () => {
    let attachimage = null;
    // console.log("1 this.state.subDirName:", this.state.subDirName);
    if (this.state.subDirName === "") {
      // console.log("call toget subdirName");
      await this.subItemSubDirectory();
    }
    // console.log("2 this.state.subDirName:", this.state.subDirName);
    let seqno = this.state.seqno;
    let ltemp = [...this.state.subitems];
    if (this.state.editFlag) {
      ltemp.forEach((obj) => {
        if (obj.seqno === this.state.seqno) {
          // console.log("obj:", obj);
          if (obj.image.key !== "") attachimage = obj.image;
        }
      });
    } else {
      seqno = ltemp.length + 1;
    }

    // if ( attachimage && attachimage.key === '') attachimage = null;

    await this.setState({
      attachPicFlag: true,
      itemVariationFlag: false,
      image: attachimage,
      seqno,
    });
  };

  returnToItemVariation = async (fileData) => {
    // console.log("(390) fileData:", fileData);
    let picS3FileData = this.state.image;
    let adjustOfDASize = 0;
    if (fileData.ops === "done") {
      if (fileData.ifDeletedFlag) {
        picS3FileData = null;
        await this.deleteDigiFileFromDb(fileData);
      }

      if (fileData.s3FileData) {
        picS3FileData = {
          key: fileData.s3FileData.key,
          type: "image",
          location: fileData.s3FileData.location,
          size: fileData.s3FileData.size,
        };
        adjustOfDASize = fileData.s3FileData.size - this.state.deletedDASize;
        // await this.attachPicAndSave(picS3FileData);
      }
    }

    let attachImageflag = this.state.attachImageflag;
    if (fileData.ops === "cancel") attachImageflag = false;

    await this.setState({
      attachPicFlag: false,
      itemVariationFlag: true,
      adjustOfDASize,
      attachImageflag,
    });

    if (fileData.ops === "done") await this.attachPicAndSave(picS3FileData);
  };

  attachPicAndSave = async (picS3FileData) => {
    // console.log("(423) picS3FileData:", picS3FileData);
    let ltemp = [];
    let sobj;
    if (this.state.editFlag) {
      this.state.subitems.forEach((obj) => {
        // console.log('***** obj:', obj);
        if (obj.seqno !== this.state.seqno) {
          // console.log('$$$$$');
          ltemp.push(obj);
        } else {
          // console.log('%%%%% this.state.selectedToEdit:', this.state.selectedToEdit);
          sobj = {
            seqno: this.state.seqno,
            name: this.state.subItemName,
            options: this.state.subItemOption,
            quantity: this.state.selectedToEdit.quantity,
            lastcost: this.state.selectedToEdit.lastcost,
            movingAvgCost: this.state.selectedToEdit.movingAvgCost,
            image: picS3FileData,
            state: this.state.opsState,
            inCatalog: this.state.inCatalog,
          };
          // console.log('@@@ sobj: ', sobj);
        }
      });
    } else {
      // console.log("(445) new subitem...");
      ltemp = [...this.state.subitems];
      sobj = {
        seqno: ltemp.length + 1,
        name: this.state.subItemName,
        options: this.state.subItemOption,
        quantity: 0,
        lastcost: 0,
        movingAvgCost: 0,
        image: picS3FileData,
        state: true,
        inCatalog: false,
      };
    }

    ltemp.push(sobj);

    let seqno = 1;
    ltemp.forEach((obj) => {
      obj.seqno = seqno;
      seqno++;
    });
    // console.log("(467) going to save in db. ltemp:", ltemp);
    await this.saveItInDB(ltemp);
  };

  deleteDigiFileFromDb = async (fileData) => {
    // console.log("delete filedata:", fileData, " seqno:", this.state.seqno);
    let ltemp = [];
    let deletedDASize = 0;
    this.state.subitems.forEach((obj) => {
      if (obj.seqno === this.state.seqno) {
        if (obj.image.size) deletedDASize = obj.image.size;
        obj.image = { key: "", type: "", location: "" };
        ltemp.push(obj);
      } else {
        ltemp.push(obj);
      }
    });
    await this.setState({
      subitems: ltemp,
      deletedDASize,
    });
  };

  checkNdeleteImageFromS3 = async (obj) => {
    // console.log("checkNdeleteImageFromS3 obj:", obj);
    // let adjustOfDASize = 0;    // This is to remove the deleted file size from community
    if (obj.image.key !== "") {
      try {
        let delfilename = obj.image.key;
        // if ( obj.image.size) adjustOfDASize = parseFloat(obj.image.size) * -1;
        // console.log('514 adjustOfDASize:', adjustOfDASize);
        let config = {
          bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
          dirName: this.state.subDirName,
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };

        await ReactS3.deleteFile(delfilename, config);
        return { status: "success", Msg: "" };
      } catch (err) {
        console.log("DeletFile from S3 error:", err.message);
        return { status: "error", Msg: err.message };
      }
    }
  };

  handleAttachImage = async () => {
    await this.setState((prevstate) => ({
      attachImageflag: !prevstate.attachImageflag,
    }));
  };

  handleSave = async () => {
    // console.log('in handleSave...')
    // console.log(">> this.state:", this.state);
    if (this.state.subItemName === "") {
      await this.setState({
        errMsg: "Sub item name cannot be blank",
        errFlag: true,
      });
    } else {
      // console.log('this.state.attachImageflag:', this.state.attachImageflag);
      if (this.state.attachImageflag) {
        // console.log("540 Going to get image and save...");
        await this.getImageAndSave();
      } else if (this.state.editFlag){
        await this.justSaveSub();
      } else {
        this.setState({
          errMsg: "A sub-item must have an image to save.",
          errFlag: true,
        });
        // await this.justSaveSub();
      }
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CreateSubItems...");

    let buttonPanel = (
      <div>
        <div className="text-center sub-sys-head">
          Item Variations Entry &nbsp;{" "}
          <button
            className="btn_info_main"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-info-circle" />
          </button>
          <button
            className="btn_back_main"
            type="button"
            onClick={this.returnToAnItemsInventory}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>
        </div>
      </div>
    );

    let itemInfoPanel = (
      <div className="text-center item-name-lbl">
        Item: {this.props.item.itemName}&nbsp;(itemId: {this.props.item.itemId})
      </div>
    );

    let entryPanel;
    entryPanel = (
      <div>
        <div className="row sub-item-entry-panel">
          <div className="col-8">
            <div className="row sub-item-entry-row">
              <div className="col-3 text-right sub-item-entry-lbl">
                Sub Name
              </div>
              <div className="col-9 text-left">
                <input
                  name="subItemName"
                  type="text"
                  value={this.state.subItemName}
                  onChange={this.onChange}
                  size="30"
                  maxLength="30"
                  className="sub-item-name"
                  placeholder="Name in 30 chrs - must be unique"
                />
              </div>
            </div>
            <div className="row sub-item-entry-row">
              <div className="col-3 text-right sub-item-entry-lbl">Options</div>
              <div className="col-9 text-left">
                <input
                  name="subItemOption"
                  type="text"
                  value={this.state.subItemOption}
                  onChange={this.onChange}
                  size="30"
                  maxLength="30"
                  className="sub-item-name"
                  placeholder="Name in 30 chrs"
                />
              </div>
            </div>
            <div className="row sub-item-entry-row">
              <div className="col text-center sub-item-image-attach">
                <Checkbox
                  checked={this.state.attachImageflag}
                  onChange={this.handleAttachImage}
                />
                &nbsp;Handle Image&nbsp;&nbsp;&nbsp;
                <button
                  className="btn_reg_60"
                  type="button"
                  onClick={this.handleSave}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Save
                </button>
                <button
                  className="btn_reg_60"
                  type="button"
                  onClick={this.handleClearEdit}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-4">
            {this.state.selectedToEdit &&
            ((this.state.selectedToEdit.image &&
              this.state.selectedToEdit.image.key === "") ||
              !this.state.selectedToEdit.image) ? (
              <div className="text-center sub-item-no-image">No Image</div>
            ) : null}
            {this.state.selectedToEdit &&
            this.state.selectedToEdit.image &&
            this.state.selectedToEdit.image.location !== "" ? (
              <img
                src={this.state.selectedToEdit.image.location}
                alt="pic"
                className="sub-item-image-to-edit"
              />
            ) : null}
          </div>
        </div>
      </div>
    );

    let bool = true;
    let subItemsListPanel;
    let subit = [...this.state.subitems];
    let trashBtnActive = true;
    let activesubs = 0;
    subit.forEach((nom) => {
      if (!nom.deleted) activesubs++;
    });
    if (activesubs === 1) trashBtnActive = false;
    // console.log('activesubs:', activesubs, ' trashBtnActive:', trashBtnActive);
    subItemsListPanel = (
      <div>
        <div className="row sub-item-list-row">
          <div className="col-3 text-center sub-item-list-head">Operations</div>
          <div className="col-9 text-center sub-item-list-head">
            Sub-name (Option) has Image{" "}
          </div>
        </div>
        <div className="fixedsize-sub-items-list">
          {subit.map((obj, i) => {
            // console.log("690 obj:", obj);
            let canDeleteASubitem = true;
            let alreadyDeleted = false;
            if (trashBtnActive) {
              if (!obj.canDelete) canDeleteASubitem = false;
              if (obj.deleted) {
                canDeleteASubitem = false;
                alreadyDeleted = true;
              }
            } else {
              canDeleteASubitem = false;
              if (obj.deleted) {
                alreadyDeleted = true;
              }
            }
            if ( parseFloat(obj.seqno) === 1) canDeleteASubitem = false;
            // let canEdit = true;
         
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-3 text-center">
                    {!canDeleteASubitem ? (
                      <button className="btn_exit_main_disabled" type="button">
                        <i className="fa fa-trash" />
                      </button>
                    ) : (
                      <button
                        className="btn_exit_main"
                        type="button"
                        onClick={() => this.selectThisItemToDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                    {alreadyDeleted ||
                    (this.props.item.willHaveItemVariance && obj.seqno <= 1) ? (
                      <button className="btn_reg_40_disabled" type="button">
                        Edit
                      </button>
                    ) : (
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisItemToedit(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                  {/* <div className="col-9 text-left item-category-label"> */}
                  <div
                    className={
                      alreadyDeleted
                        ? "col-9 text-left item-category-label-x-deleted"
                        : "col-9 text-left item-category-label-x"
                    }
                  >
                    {obj.name}&nbsp;&nbsp;({obj.options}){" "}
                    {/* {obj.image.key !== "" ? "Image" : "No-Image"}  */}
                    {!obj.image ||
                    (obj.image && obj.image.key === "") ? null : (
                      <i className="fa fa-paperclip" />
                    )}
                    &nbsp;
                    {alreadyDeleted ? (
                      <font color="red">Deleted/Inactive</font>
                    ) : null}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center sub-item-msg-err"
              : "text-center sub-item-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.itemVariationFlag) {
      outputPanel = (
        <div className="sub-item-box">
          {buttonPanel}
          {itemInfoPanel}
          {entryPanel}
          {subItemsListPanel}
        </div>
      );
    }

    if (this.state.attachPicFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToItemVariation}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="Sub-item Item Image"
            s3FileData={this.state.image}
            type="image"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateSubItems.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateSubItems));
