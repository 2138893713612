import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import "./MarketMain.css";

import MarketLanding from "./landing/MarketLanding";
import Catalog from "./catalog/Catalog";
import Store from "./store/Store";
import AdminRouter from "../administration/AdminRouter";
import ConnectHub from "../connect/ConnectHub";
import ActionMain from "./action/ActionMain";

import ViewCatalog from "./catalog/ViewPagiCatalog";
import Markets from "./coop/Markets";
import StoreKeepHub from "./coopmgmt/StoreKeepHub";

import AStoreLanding from "./coop/landing/AStoreLanding";

import placeHolderBanner from "../../../images/defaultBanner2.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getFunctionsOfRole = "/routes/architecture/getFunctionsOfRole?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const getCatalogItemCount = "/routes/dashboard/getCatalogItemCount?";
const getGuestTempBaandaId = "/routes/dashboard/getGuestTempBaandaId?";

const catalogExternalList = ["cataloguser"];
const catalogInternalList = ["catalogsetup", "sales", "cartmgmt"];
const storeButtonList = [
  "shipping",
  "pickup",
  "returnhandling",
  "inventoryctrl",
  "recvpayment",
  "expense",
  "budgeting",
  "buycredits",
];
const adminAccessList = ["admincore", "adminhr"];
// const storeKeeperList = ["storekeeper"];
const storeOwnerList = ["storekeeper", "storeowner"];

const readMeCode = "1110000000";

class MarketMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      authorizedFuncs: [],
      showMarketLandingFlag: false,
      showStoreLandingFlag: false,

      catalogFlag: false,
      storeFlag: false,
      adminstrationFlag: false,

      btnStoreFlag: false,
      btnCatalogInternalFlag: false,
      btnCatalogExternalFlag: false,
      btnAdminstrationFlag: false,
      btnStoreKeeperFlag: false,

      showOutsiderTheCatalogFlag: false,
      calledFrom: "",
      itemId: 0,

      connectFlag: false,
      isMember: false,

      reportsFlag: false,
      actionsFlag: false,

      catalogUserFlag: false,
      marketFlag: false,

      marketUserFlag: false,
      storekeeperFlag: false,

      banner: "",

      selectedStore: null,

      publishCatalogItems: null,
      coopStoreId: 0,

      bannerProcessDone: false,

      cartOf: null,
      compDidMountDoneFlag: false,
    };

    this.doThisAtFirst();
  }

  doThisAtFirst = () => {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.history.goForward();
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let cartOf = this.props.auth.user;
    if (this.props.customerType === "guest") {
      let getTG = await this.getAGusetBaandaId();
      if (getTG.status === "success") {
        cartOf.baandaId = getTG.Msg;
      } else {
        cartOf.baandaId = 1000000000009; // This is a rare case (should never happen unless internet and Atlas is down)
      }
    }
    this.setState({
      cartOf,
    });

    await this.setFunctionOfRole();
    await this.getCommunityInfo();
    await this.setAuthorizedButtons();

    await this.setCommunityBanner(deviceSize);

    await this.getNoOnPublishedItems();

    let calledFrom = "";
    let itemId = 0;
    let isMember = false;
    if (this.state.community) {
      this.state.community.members.forEach((mem) => {
        if (mem.baandaId === this.props.auth.user.baandaId) isMember = true;
      });
    }

    let showMarketLandingFlag = false;
    if (this.props.comingFrom === "bazzar") showMarketLandingFlag = true;

    let catalogFlag = false;
    if (this.props.customerType === "guest") catalogFlag = true;

    let showOutsiderTheCatalogFlag = false;
    let catalogUserFlag = false;

    if (
      this.props.comingFrom === "bazzar" &&
      this.props.calledFrom === "web" &&
      Number(this.props.itemId) > 0
    ) {
      showMarketLandingFlag = false;
      calledFrom = this.props.calledFrom;
      itemId = Number(this.props.itemId);
      showOutsiderTheCatalogFlag = true;
      catalogUserFlag = true;
    }

    this.setState({
      bannerProcessDone: true,
      deviceSize,
      showMarketLandingFlag,
      showOutsiderTheCatalogFlag,
      isMember,
      catalogFlag,
      catalogUserFlag,
      calledFrom,
      itemId,
      compDidMountDoneFlag: true,
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getAGusetBaandaId = async () => {
    let url = baandaServer + getGuestTempBaandaId;
    try {
      let tbret = await axios.get(url);
      if (tbret.data.status === "success") {
        return { status: "success", Msg: parseFloat(tbret.data.Msg) };
      } else {
        return { status: "error", Msg: tbret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  getNoOnPublishedItems = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=0&isPublished=true";
    let url = baandaServer + getCatalogItemCount + params;
    try {
      let cntret = await axios.get(url);
      if (cntret.data.status === "success") {
        this.setState({
          publishCatalogItems: cntret.data.Msg,
        });
      } else {
        console.log("Error:", cntret.data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  goToDashboard = async () => {
    this.props.dashReturnMethod(this.props.comingFrom, this.props.opstype);
  };

  setStoreKeepAcces = async () => {
    let params =
      "role=selectedstoreadmin&communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;

    let url = baandaServer + getFunctionsOfRole + params;
    try {
      let ret = await axios.get(url);
      let af = ret.data.Msg[0].functionsAllowed;

      let afString = af.toString();
      afString = af.toString().replace("[", "");
      afString = afString.replace("]", "");

      let arr = afString.replace(/ /g, "").split(",");
      let outarr = [];
      // Replace the quotes ' characters from the list of string of functions
      // and form the output array
      arr.forEach((obj) => {
        let x = obj.replace("'", "");
        let x1 = x.replace("'", "");
        outarr.push(x1);
      });

      await this.setState({
        authorizedFuncs: outarr,
      });
    } catch (err) {
      console.log("onMount Err:", err.message);
    }
  };

  setFunctionOfRole = async () => {
    let role = "";
    if (this.props.comingFrom === "accesslist") {
      role = this.props.role.toLowerCase();
    } else {
      if (this.props.isCustomer) role = "customer";
      else role = "prospect";
    }

    let params =
      "role=" +
      role +
      "&communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;

    let url = baandaServer + getFunctionsOfRole + params;
    try {
      let ret = await axios.get(url);

      let af = ret.data.Msg[0].functionsAllowed;

      let afString = af.toString();
      afString = af.toString().replace("[", "");
      afString = afString.replace("]", "");

      let arr = afString.replace(/ /g, "").split(",");
      let outarr = [];
      // Replace the quotes ' characters from the list of string of functions
      // and form the output array
      arr.forEach((obj) => {
        let x = obj.replace("'", "");
        let x1 = x.replace("'", "");
        outarr.push(x1);
      });

      await this.setState({
        authorizedFuncs: outarr,
      });
    } catch (err) {
      console.log("onMount Err:", err.message);
    }
  };

  getCommunityInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      let isOpenForBusiness = false;
      let isOpenForPrep = false;
      if (retData.data.Msg.adminTaskStates.openForBusinessFlag)
        isOpenForBusiness = true;
      if (retData.data.Msg.adminTaskStates.openForInternalSetupFlag)
        isOpenForPrep = true;
      await this.setState({
        community: retData.data.Msg,
        isOpenForBusiness,
        isOpenForPrep,
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
    }
  };

  setAuthorizedButtons = async () => {
    let btnStoreFlag = false;
    let btnCatalogInternalFlag = false;
    let btnCatalogExternalFlag = false;
    let btnAdminstrationFlag = false;
    let btnStoreKeeperFlag = false;
    let storeFlag = false;

    storeButtonList.forEach((obj1) => {
      if (this.state.authorizedFuncs.includes(obj1)) {
        btnStoreFlag = true;
        // storeFlag = true;
      }
    });
    catalogInternalList.forEach((obj2) => {
      if (this.state.authorizedFuncs.includes(obj2)) {
        btnCatalogInternalFlag = true;
      }
    });
    catalogExternalList.forEach((obj3) => {
      if (this.state.authorizedFuncs.includes(obj3))
        btnCatalogExternalFlag = true;
    });
    adminAccessList.forEach((obj3) => {
      if (this.state.authorizedFuncs.includes(obj3))
        btnAdminstrationFlag = true;
    });

    let showOutsiderTheCatalogFlag = false;
    if (btnCatalogExternalFlag && this.props.comingFrom !== "bazzar")
      showOutsiderTheCatalogFlag = true;

    if (this.state.community && this.state.community.communityType === "coop") {
      storeOwnerList.forEach((obj5) => {
        if (this.state.authorizedFuncs.includes(obj5)) {
          btnStoreKeeperFlag = true;
        }
      });
      showOutsiderTheCatalogFlag = false;

      if (this.state.coopStoreId === 0 && this.props.role === "storeowner") {
        btnCatalogInternalFlag = false;
        btnStoreFlag = false;
      }
    }

    if (!this.state.community.adminTaskStates.financialYearSetup) {
      this.setState({
        btnAdminstrationFlag,
      });
    } else {
      this.setState({
        btnStoreFlag,
        btnCatalogInternalFlag,
        btnCatalogExternalFlag,
        btnAdminstrationFlag,
        btnStoreKeeperFlag,
        storeFlag,
        showOutsiderTheCatalogFlag,
      });
    }
  };

  setAuthorizedBazaarButtons = async () => {};

  setCommunityBanner = async (deviceSize) => {
    let bannerSize;

    if (deviceSize === "big") bannerSize = "bannerLarge";
    else bannerSize = "bannerSmall";
    this.state.community.fileUploads.forEach((obj) => {
      if (obj.type === bannerSize) {
        this.setState({
          banner: obj.location,
        });
      }
    });
  };

  handleSelectedFunction = async (selected) => {
    // IF selected === storekeeper then remove Catalog, Store, Connect ... do we need to do this?
    let btnCatalogInternalFlag = this.state.btnCatalogInternalFlag;
    let btnStoreFlag = this.state.btnStoreFlag;
    this.setState({
      showOutsiderTheCatalogFlag: false,
      catalogFlag: false,
      connectFlag: false,
      storeFlag: false,
      reportsFlag: false,
      actionsFlag: false,
      adminstrationFlag: false,
      showMarketLandingFlag: false,
      catalogUserFlag: false,
      marketFlag: false,
      marketUserFlag: false,
      storekeeperFlag: false,
      showStoreLandingFlag: false,
    });
    if (this.state.community.communityType === "coop") {
      if (selected === "storekeeper") {
        btnCatalogInternalFlag = false;
        btnStoreFlag = false;
      }
      this.setState({
        btnCatalogInternalFlag,
        btnStoreFlag,
      });
    }
    if (selected === "catalog") {
      this.setState({
        catalogFlag: true,
      });
    }
    if (selected === "store") {
      this.setState({
        storeFlag: true,
      });
    }
    if (selected === "connect") {
      this.setState({
        connectFlag: true,
      });
    }
    if (selected === "administration") {
      this.setState({
        adminstrationFlag: true,
      });
    }
    if (selected === "cataloguser") {
      this.setState({
        showOutsiderTheCatalogFlag: true,
        catalogUserFlag: true,
      });
    }
    if (selected === "action") {
      this.setState({
        actionsFlag: true,
      });
    }

    if (selected === "storekeeper") {
      this.setState({
        storekeeperFlag: true,
        selectedStore: null,
      });
    }

    if (selected === "market") {
      this.setState({
        marketFlag: true,
        marketUserFlag: true,
      });
    }
  };

  // storekeeper
  returnFromStorekeep = async (store) => {
    let coopStoreId = 0;
    if (store && store.coopStoreId > 0) coopStoreId = store.coopStoreId;
    this.setState({
      selectedStore: store,
      storekeeperFlag: false,
      showStoreLandingFlag: true,
      coopStoreId,
    });

    await this.setStoreKeepAcces();
    await this.setAuthorizedButtons();
  };

  returnFromStoreMarket = () => {
    this.setState({
      marketFlag: false,
      showMarketLandingFlag: true,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("MarketMain...");

    let bannerPanel;

    if (this.state.bannerProcessDone) {
      if (this.state.banner !== "") {
        bannerPanel = (
          <div className="text-center mkt-ban-pos-xx">
            <img
              src={this.state.banner}
              className="market-main-banner"
              alt="banner"
            />
          </div>
        );
      } else {
        bannerPanel = (
          <div className="text-center mkt-ban-pos-xx">
            <img
              src={placeHolderBanner}
              className="market-main-banner"
              alt="banner"
            />
          </div>
        );
      }
    }

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.goToDashboard}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let buttonCatalog,
      buttonAdmin,
      store,
      buttonConnect,
      buttonAction,
      buttonCatlogUser,
      storeKeeper,
      buttonMarkets;
    if (this.state.deviceSize === "small") {
      buttonCatalog = "Cat";
      buttonAdmin = "Adm";
      store = "Str";
      buttonConnect = "Cnt";
      buttonAction = "Ops";
      buttonCatlogUser = "Cat";
      buttonMarkets = "Mkt";
      storeKeeper = "Kpr";
    } else {
      buttonCatalog = "Catalog";
      buttonAdmin = "Admin";
      store = "Store";
      buttonConnect = "Connect";
      buttonAction = "Operate";
      buttonCatlogUser = "Catalog";
      buttonMarkets = "Market";
      storeKeeper = "Keeper";
    }

    let buttonPanel;
    if (this.props.comingFrom === "accesslist") {
      buttonPanel = (
        <div className="market-main-page-pos">
          <div className=" text-center">{bannerPanel}</div>
          <div className="text-center market-main-buttons-pos">
            {this.state.btnCatalogInternalFlag && this.state.isOpenForPrep ? (
              <button
                className={`${
                  this.state.catalogFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("catalog")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonCatalog
                ) : (
                  <i className="fas fa-store" />
                )}
              </button>
            ) : null}
            {this.state.btnStoreFlag && this.state.isOpenForPrep ? (
              <button
                className={`${
                  this.state.storeFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("store")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  store
                ) : (
                  <i className="fas fa-warehouse" />
                )}
              </button>
            ) : null}
            {this.props.role !== "prospect" && this.state.isOpenForPrep ? (
              <button
                className={`${
                  this.state.connectFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("connect")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonConnect
                ) : (
                  <i className="fas fa-street-view" />
                )}
              </button>
            ) : null}
            {this.props.role !== "prospect" && this.state.isOpenForPrep ? (
              <button
                className={`${
                  this.state.actionsFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("action")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonAction
                ) : (
                  <i className="fas fa-tasks" />
                )}
              </button>
            ) : null}
            {this.state.btnAdminstrationFlag ? (
              <button
                className={`${
                  this.state.adminstrationFlag
                    ? "btn_reg_75_active"
                    : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("administration")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonAdmin
                ) : (
                  <i className="fas fa-cog" />
                )}
              </button>
            ) : null}
            {this.state.btnStoreKeeperFlag ? (
              <button
                className={`${
                  this.state.storekeeperFlag
                    ? "btn_reg_75_active"
                    : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("storekeeper")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.deviceSize === "big" ? (
                  storeKeeper
                ) : (
                  <i className="fas fa-user-cog" />
                )}
              </button>
            ) : null}
            {infoButton} {backButton}
          </div>
        </div>
      );
      // }
    }

    if (
      (this.props.comingFrom === "bazzar") &
      (this.props.customerType !== "guest")
    ) {
      if (
        this.state.community &&
        this.state.community.communityType === "standard"
      ) {
        if (this.props.isCustomer) {
          buttonPanel = (
            <div className="outsider-main-ctrl-button-pos">
              <div className="outsider-banner-pos">{bannerPanel}</div>
              {this.state.publishCatalogItems &&
              this.state.publishCatalogItems > 0 ? (
                <button
                  className={`${
                    this.state.catalogUserFlag
                      ? "btn_reg_75_active"
                      : "btn_reg_75"
                  }`}
                  type="button"
                  onClick={() => this.handleSelectedFunction("cataloguser")}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {this.state.deviceSize === "big" ? (
                    buttonCatlogUser
                  ) : (
                    <i className="fas fa-store" />
                  )}
                </button>
              ) : null}
              <button
                className={`${
                  this.state.connectFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("connect")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonConnect
                ) : (
                  <i className="fas fa-street-view" />
                )}
              </button>
              <button
                className={`${
                  this.state.actionsFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("action")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonAction
                ) : (
                  <i className="fas fa-tasks" />
                )}
              </button>
              {infoButton} {backButton}
            </div>
          );
        } else {
          buttonPanel = (
            <div className="outsider-main-ctrl-button-pos">
              <div className="outsider-banner-pos">{bannerPanel}</div>
              {this.state.publishCatalogItems &&
              this.state.publishCatalogItems > 0 ? (
                <button
                  className={`${
                    this.state.catalogUserFlag
                      ? "btn_reg_75_active"
                      : "btn_reg_75"
                  }`}
                  type="button"
                  onClick={() => this.handleSelectedFunction("cataloguser")}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {this.state.deviceSize === "big" ? (
                    buttonCatlogUser
                  ) : (
                    <i className="fas fa-store" />
                  )}
                </button>
              ) : null}
              <button
                className={`${
                  this.state.connectFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("connect")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonConnect
                ) : (
                  <i className="fas fa-street-view" />
                )}
              </button>
              {infoButton} {backButton}
            </div>
          );
        }
      }

      if (
        this.state.community &&
        this.state.community.communityType === "coop"
      ) {
        buttonPanel = (
          <div className="outsider-main-ctrl-button-pos">
            <div className="outsider-banner-pos">{bannerPanel}</div>
            <button
              className={`${
                this.state.marketUserFlag ? "btn_reg_75_active" : "btn_reg_75"
              }`}
              type="button"
              onClick={() => this.handleSelectedFunction("market")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {this.state.deviceSize === "big" ? (
                buttonMarkets
              ) : (
                <i className="fas fa-store" />
              )}
            </button>
            {this.props.role === "customer" ? (
              <button
                className={`${
                  this.state.actionsFlag ? "btn_reg_75_active" : "btn_reg_75"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("action")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === "big" ? (
                  buttonAction
                ) : (
                  <i className="fas fa-tasks" />
                )}
              </button>
            ) : null}
            <button
              className={`${
                this.state.connectFlag ? "btn_reg_75_active" : "btn_reg_75"
              }`}
              type="button"
              onClick={() => this.handleSelectedFunction("connect")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {this.state.deviceSize === "big" ? (
                buttonConnect
              ) : (
                <i className="fas fa-street-view" />
              )}
            </button>
            {infoButton} {backButton}
          </div>
        );
      }
    }

    if (this.props.customerType === "guest") {
      buttonPanel = (
        <div className="outsider-main-ctrl-button-pos">
          <div className="outsider-banner-pos">{bannerPanel}</div>
          <button
            className={`${
              this.state.catalogFlag ? "btn_reg_75_active" : "btn_reg_75"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("catalog")}
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              buttonCatalog
            ) : (
              <i className="fas fa-store" />
            )}
          </button>

          {infoButton}
        </div>
      );
    }

    let activePanel;
    if (this.state.connectFlag && this.state.isOpenForPrep) {
      let store = null;
      if (this.state.selectedStore) store = this.state.selectedStore;
      activePanel = (
        <div>
          <ConnectHub
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            isCustomer={this.props.isCustomer}
            isMember={this.state.isMember}
            role={this.props.role}
            authorizedFuncs={this.state.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.actionsFlag && this.state.isOpenForPrep) {
      let store = null;
      if (this.state.selectedStore) store = this.state.selectedStore;
      activePanel = (
        <div>
          <ActionMain
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            isCustomer={this.props.isCustomer}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            isMember={this.state.isMember}
            authorizedFuncs={this.state.authorizedFuncs}
            comingFrom={this.props.comingFrom}
            store={store}
          />
        </div>
      );
    }

    if (this.state.adminstrationFlag) {
      activePanel = (
        <div>
          <AdminRouter
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorizedFuncs={this.state.authorizedFuncs}
          />
        </div>
      );
    }

    if (this.state.storeFlag) {
      let store = null;
      if (this.state.selectedStore) store = this.state.selectedStore;
      activePanel = (
        <div>
          <Store
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            isMember={this.state.isMember}
            isCustomer={this.props.isCustomer}
            authorizedFuncs={this.state.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.catalogFlag) {
      let store = null;
      if (this.state.selectedStore) store = this.state.selectedStore;
      activePanel = (
        <div>
          <Catalog
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorization="catalog"
            authorizedFuncs={this.state.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (
      (this.state.showOutsiderTheCatalogFlag ||
        this.props.customerType === "guest") &&
      this.state.compDidMountDoneFlag
    ) {
 
      let customerType = "";
      if (this.props.customerType) customerType = this.props.customerType;
      let itemId = 0;
      if (this.props.itemId) itemId = Number(this.props.itemId);
      activePanel = (
        <div>
          <ViewCatalog
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorization="cataloguser"
            authorizedFuncs={this.state.authorizedFuncs}
            cartOf={this.state.cartOf}
            caller="buyer"
            customerType={customerType}
            itemId={itemId}
            calledFrom={this.state.calledFrom}
          />
        </div>
      );
    }

    if (this.state.marketFlag) {
      activePanel = (
        <div>
          <Markets
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorization="cataloguser"
            authorizedFuncs={this.state.authorizedFuncs}
            cartOf={this.props.auth.user}
            caller="buyer"
            returnToCaller={this.returnFromStoreMarket}
          />
        </div>
      );
    }

    if (this.state.storekeeperFlag) {
      activePanel = (
        <div>
          <StoreKeepHub
            returnToMain={(store) => this.returnFromStorekeep(store)}
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.state.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorization="cataloguser"
            authorizedFuncs={this.state.authorizedFuncs}
            cartOf={this.props.auth.user}
            caller="buyer"
          />
        </div>
      );
    }

    if (
      this.state.showMarketLandingFlag &&
      this.props.comingFrom === "bazzar" &&
      this.props.customerType !== "guest"
    ) {
      activePanel = (
        <div>
          <MarketLanding
            communityId={this.props.communityId}
            community={this.state.community}
            itemId={this.props.itemId}
            customerType={this.props.customerType}
          />
        </div>
      );
    }

    if (
      this.state.showStoreLandingFlag &&
      this.props.comingFrom === "accesslist"
    ) {
      activePanel = (
        <div>
          <div className="text-center a-landing-pov-header">
            Customer's POV of the store
          </div>
          <AStoreLanding
            communityId={this.props.communityId}
            community={this.state.community}
            store={this.state.selectedStore}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        {buttonPanel}
        {activePanel}
      </div>
    );

    return (
      <div className="text-center">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

MarketMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketMain)
);
